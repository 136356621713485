import React from "react"
import { Box, Text, Grid, Button, Flex } from "theme-ui"
import Layout from "../../components/Layout"
import PageHeader from "../../components/PageHeader"
import SubInfoBox from "../../components/SubInfoBox"
import Img from "gatsby-image"
import SEO from "../../components/SEO"
import { Link, graphql } from "gatsby"
import { CloudDownload } from "@emotion-icons/boxicons-regular/CloudDownload"


interface BookCardProps {
  image: any
  name: string
}

const BookCard = (props: BookCardProps): JSX.Element => {
  const { image, name } = props
  return (
    <Box>
      <Box
        p={3}
        sx={{
          borderTop: "1px solid #70707057",
          borderLeft: "1px solid #70707057",
          borderRight: "1px solid #70707057",
        }}
      >
        <Img fluid={image} alt="book" />
      </Box>
      <Text
        sx={{
          border: "1px solid #70707057",
          textAlign: "center",
          fontSize: [0, 1],
        }}
        py={2}
        px={3}
      >
        {name}
      </Text>
     <Link to="/enroll">
        <Button
          sx={{
            borderRadius: "0",
            width: "100%",
            background: "#18BC25D6",
            color: "white",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <Flex sx={{ justifyContent: "center", alignItems: "flex-end" }}>
            <Text sx={{ fontSize: [0, 1] }} variant="caps">
              Download Now
            </Text>
            <Box ml={3}>
              <CloudDownload size="1.6rem" />
            </Box>
          </Flex>
        </Button>
       </Link>
    </Box>
  )
}

export const bookImage = graphql`
  fragment bookImage on File {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const query = graphql`
  query {
    book: file(relativePath: { eq: "book1.jpg" }) {
      ...bookImage
    }
  }
`

const BooksPage = ({ data }): JSX.Element => (
  <Box>
    <SEO title="Books by Sarkar Academy" />
    <Layout>
      <PageHeader>
        <Text
          sx={{ fontSize: [3, 5], fontWeight: "bold", textAlign: "center" }}
        >
          “Books by Sarkar Academy”
        </Text>
      </PageHeader>
      <SubInfoBox>
        WELL UPDATED LATEST BOOKS PREPARED BY SARKAR IAS ACADEMY IS AVAILABLE
        FOR FREE.
      </SubInfoBox>
      <Box my={6}>
        <Grid
          columns={[1, 2, 3, 3, 4]}
          gap={5}
          px={4}
          sx={{ maxWidth: "1300px", marginTop: "-50px" }}
        >
         
            <BookCard
              image={data.book.childImageSharp.fluid}
              name={"UPSC Prelims Main Part-I&II"}
            />
         
        </Grid>
      </Box>
    </Layout>
  </Box>
)

export default BooksPage
